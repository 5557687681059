import React from "react";
import Layout from "../../components/layout";
import {
  Container,
  H1,
  NextStep,
  PrevStep,
  StepIndicator,
} from "../../styles/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Contact from "../../components/contact";
import Card from "../../components/card";
import { FiCheckCircle } from "react-icons/fi";

const Megvalositas: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Megvalósítás"
      description="Megvalósítás"
    >
      <Wrapper>
        <Inner>
          <div
            css={css`
              grid-area: txt;
              h3 {
                margin: 32px 0;
              }
            `}
          >
            <StepIndicator step={3} />
            <H1>Megvalósítás</H1>
            <p>
              A marketing- és kereskedelmi tervezés stratégiai (tervezési) és
              taktikai (megvalósítási) elemeket is magában foglal, ezért
              szolgáltatásunk harmadik eleme a taktikai eszközök megvalósítására
              tér ki. A taktikai döntéseknek a stratégiai tervre kell épülniük,
              amely átfogó auditból származó adatokra támaszkodik. A
              megvalósítás során teljes körű marketing és kereskedelmi
              eszköztárral rendelkezünk. Elsősorban az anyagok gyártására kerül
              sor, majd azoknak a publikációjára.
            </p>
            <h3>Nyomdai anyagok</h3>
            <p>
              Flyerek, poszterek kreatívjainak elkészítése. Könyvek, kiadványok,
              teljes arculatok (logó, névjegy, levélpapír, boríték, dosszié,
              banner) teljeskörű grafikai tervezése. Könyvek, prospektusok
              tördelése, a rendelkezésre álló képek és szövegek végleges formába
              rendezése. Perszonalizált (képi, szöveges, vonalkód, barkód, QR
              kód) anyagok előkészítése, hibásan leadott anyagok javítása. Kész
              anyagból mutációk készítése plakáthoz, webre, hirdetésekhez.
            </p>
          </div>
          <div
            css={css`
              grid-area: card;
            `}
          >
            <Card>
              <h2>Kereskedelmi eszközök és területek</h2>
              <ul style={{ padding: 0 }}>
                <Li>Online és offline ATL-BTL</Li>
                <Li>Online marketing</Li>
                <Li>Kampányok</Li>
                <Li>Kommunikáció</Li>
                <Li>PR</Li>
                <Li>Reklámügynökségi tevékenység</Li>
                <Li>Piackutatás</Li>
                <Li>Stratégiaalkotás</Li>
                <Li>Megvalósítás/projekt management</Li>
                <Li>Oktatás/továbbképzés</Li>
                <Li>Tartalom készítés(cikkek,interjúk, posztok)</Li>
                <Li>Fotózás pl.: termékfotózás</Li>
                <Li>Videó (reklámfilm)</Li>
                <Li>Grafikai munka: arculattervezés, logó</Li>
                <Li>Nyomdai anyagok és azok előkészítése</Li>
              </ul>
            </Card>
          </div>
          <PrevNext
            css={css`
              grid-area: nav;
            `}
          >
            <PrevStep step={2} name={"Növekedési stratégia kidolgozása"} />
            {/* <div /> */}
            <NextStep step={4} name={"Eredményességmérés"} />
          </PrevNext>
        </Inner>
      </Wrapper>
      <Contact variant="marketing" />
    </Layout>
  );
};

export default Megvalositas;

interface LiProps {
  children?: React.ReactNode;
}
const Li: React.FC<LiProps> = ({ children }) => (
  <li
    css={css`
      list-style: none;
      display: grid;
      grid-template-columns: 20px 1fr;
      column-gap: 8px;
      margin-bottom: 16px;
      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        color: var(--color-primary);
        fill: none;
      }
      p {
        display: inline-block;
      }
    `}
  >
    <FiCheckCircle />
    <p>{children}</p>
  </li>
);

const Wrapper = styled(Container)`
  margin: 160px 0 96px 0;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;

  grid-template-areas:
    "txt card"
    "nav card";
  column-gap: 32px;
  row-gap: 64px;

  h2 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  @media (max-width: 1310px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "txt"
      "card"
      "nav";
  }
`;

const PrevNext = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 32px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
